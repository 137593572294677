var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{class:{
      'pa-5 pt-4 pb-4': true,
      'timeline-header': _vm.timeline,
    }},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12"}},[_vm._t("header")],2),(!_vm.disableDefaultHeader)?_c('div',[(_vm.topicName)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.topicName))])]):_vm._e(),(_vm.credTitle)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12"}},[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.credTitle["key"])+": "+_vm._s(_vm.translateValue( _vm.credTitle["accessor"], _vm.credTitle["value"], _vm.entityType ))+" ")])]):_vm._e()],1):_vm._e()],1)],1),_c('v-expansion-panel-content',{class:{
      'pt-0 px-n1': true,
      'pt-4 timeline-content': _vm.timeline,
    }},[_c('div',{staticClass:"ma-n1 py-1 text-body-2 text--secondary"},[_c('div',{class:{ 'pb-3': _vm.timeline && !_vm.highlightedAttr.length }},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Authority")]),_c('div',[(_vm.getAuthorityLink)?_c('a',{attrs:{"href":_vm.getAuthorityLink}},[_c('span',[_vm._v(_vm._s(_vm.getAuthority))]),_c('v-icon',{staticClass:"fake-link",attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiOpenInNew))])],1):_c('span',[_vm._v(_vm._s(_vm.getAuthority))])])]),_c('v-row',_vm._l((_vm.highlightedAttr),function(attr,i){return _c('v-col',{key:i,staticClass:"pl-0",attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(attr["key"]))]),_c('div',[_vm._v(" "+_vm._s(_vm.translateValue(attr["accessor"], attr["value"], _vm.entityType))+" ")])])}),1),_c('div',[(!_vm.getCredRevoked)?_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiShieldCheckOutline))]):_vm._e(),_c('router-link',{staticClass:"vertical-align-middle",attrs:{"to":{
            name: 'Credential',
            params: { sourceId: _vm.sourceId, credentialId: _vm.getCredId },
          }}},[_vm._v("Credential"),(!_vm.getCredRevoked)?_c('span',[_vm._v(" verified")]):_c('span',[_vm._v(" claims")])])],1),(_vm.effectiveDate)?_c('div',[_c('span',[_vm._v("Effective: ")]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.effectiveDate)))])]):_vm._e()],1),_vm._t("content")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }