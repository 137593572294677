var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"multiple":"","active-class":""}},_vm._l((_vm.fields),function(field){return _c('v-list-item',{key:field.value,staticClass:"facet-filter pa-0",on:{"click":function($event){return _vm.toggleEntityFilter({
          filterString: field.value,
          filterField: _vm.filterField,
        })}},scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-action',{staticClass:"mt-1 mb-1 mr-1"},[_c('v-simple-checkbox',{staticClass:"checkbox",attrs:{"ripple":false,"input-value":active,"value":_vm.isEntityFilterActive(_vm.filterField, _vm.getEntityFilters, field.value)},on:{"click":function($event){return _vm.toggleEntityFilter({
                filterString: field.value,
                filterField: _vm.filterField,
              })}}})],1),_c('v-list-item-content',{directives:[{name:"translate",rawName:"v-translate",value:(_vm.toTranslationFormat(field.value, _vm.entityType)),expression:"toTranslationFormat(field.value, entityType)"}],staticClass:"pt-1 pb-1"}),_c('v-list-item-action',{staticClass:"d-flex justify-end mt-1 mb-1"},[_c('div',[_vm._v(_vm._s(field.count))])])]}}],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }