
import { IEntityFacetField } from "@/interfaces/entity-filter.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import EntityFilterFacets from "@/components/entity/filter/EntityFilterFacets.vue";

interface Data {
  show: boolean;
}

@Component({
  components: {
    EntityFilterFacets,
  },
})
export default class EntityFilterFacetPanel extends Vue {
  @Prop({ default: "" }) entityType!: string;
  @Prop({ default: () => [] }) filterField!: string;
  @Prop({ default: () => [] }) fields!: IEntityFacetField[];
  @Prop({ default: () => [] }) more!: IEntityFacetField[];

  data(): Data {
    return {
      show: false,
    };
  }
}
